<template>
  <div class="chain">
    <H5Navheader :name="'链上公示'" />
    <!-- 主体内容 -->
    <div class="main-body">
      <div class="block-card">
        <div class="image"></div>
        <div class="text">
          <div class="zh">区块高度</div>
          <div class="en">Block height on the chain</div>
        </div>
        <DigitRoll :rollDigits="number" :dur="1500" v-if="number"/>
      </div>
      <div class="data-info-wrap">
        <div class="list">
          <div class="item">
            <div class="title">订单数据</div>
            <div class="value">
              <el-statistic group-separator="," :precision="0" :value="this.statisticsData[0]?.much"></el-statistic>
            </div>
            <div class="en-title">Order data</div>
          </div>
          <div class="item">
            <div class="title">会员数据</div>
            <div class="value">
              <el-statistic group-separator="," :precision="0" :value="this.statisticsData[3].much"></el-statistic>
            </div>
            <div class="en-title">Member data</div>
          </div>
          <div class="item">
            <div class="title">溯源数据</div>
            <div class="value">
              <el-statistic group-separator="," :precision="0" :value="this.statisticsData[1].much"></el-statistic>
            </div>
            <div class="en-title">Traceability data</div>
          </div>
          <div class="item">
            <div class="title">商品数据</div>
            <div class="value">
              <el-statistic group-separator="," :precision="0" :value="this.statisticsData[2].much"></el-statistic>
            </div>
            <div class="en-title">Item data</div>
          </div>
        </div>
      </div>
      <div class="search-box">
        <el-input
          placeholder="请输入交易哈希/区块高度/区块哈希"
          v-model="keyword"
          @keyup.enter.native="handleQuery"
          >
          <i slot="suffix" class="el-input__icon el-icon-search" @click="handleQuery"></i>
        </el-input>
      </div>
      <div class="table-wrap-pc" style="margin-bottom: 124px">
        <div class="title">{{title}}</div>
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="ID" width="160" show-overflow-tooltip>
            <!-- <template slot-scope="scope">
              <router-link to="/address">{{scope.row.blockNumber}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="dataHash" label="数据哈希" width="300">
            <!-- <template slot-scope="scope">
              <router-link to="/hexDetail">{{scope.row.name}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="blockHash" label="区块哈希" width="300"> </el-table-column>
          <el-table-column prop="blockNumber" label="区块高度" width="130"> </el-table-column>
          <el-table-column prop="createTime" label="生成时间"> </el-table-column>
          <el-table-column label="操作" align="center"  width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="goData(scope.row)" style="color:#1DADF9" >查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination v-show="total > 0" :total="total" :page.sync="params.pageNum" :limit.sync="params.pageSize"
          @pagination="getOrder" />
      </div>
      <div class="table-wrap-pc" style="padding-bottom: 124px" v-if="!searchStatus">
        <div class="title">会员数据</div>
        <el-table v-loading="loading2" :data="tableData2" style="width: 100%">
          <el-table-column prop="id" label="ID" width="160" show-overflow-tooltip>
            <!-- <template slot-scope="scope">
              <router-link to="/address">{{scope.row.blockNumber}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="dataHash" label="数据哈希" width="300">
            <!-- <template slot-scope="scope">
              <router-link to="/hexDetail">{{scope.row.name}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="blockHash" label="区块哈希" width="300"> </el-table-column>
          <el-table-column prop="blockNumber" label="区块高度" width="130"> </el-table-column>
          <el-table-column prop="createTime" label="生成时间"> </el-table-column>
          <el-table-column label="操作" align="center"  width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="goData(scope.row)" style="color:#1DADF9">查看详情</el-button>
            </template>
        </el-table-column>
        </el-table>
        <pagination v-show="total2 > 0" :total="total2" :page.sync="params2.pageNum" :limit.sync="params2.pageSize"
          @pagination="getMember" />
      </div>
      <div class="table-wrap-pc" style="padding-bottom: 124px" v-if="!searchStatus">
        <div class="title">溯源数据</div>
        <el-table v-loading="loading3" :data="tableData3" style="width: 100%">
          <el-table-column prop="id" label="ID" width="160" show-overflow-tooltip>
            <!-- <template slot-scope="scope">
              <router-link to="/address">{{scope.row.blockNumber}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="dataHash" label="数据哈希" width="300">
            <!-- <template slot-scope="scope">
              <router-link to="/hexDetail">{{scope.row.name}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="blockHash" label="区块哈希" width="300"> </el-table-column>
          <el-table-column prop="blockNumber" label="区块高度" width="130"> </el-table-column>
          <el-table-column prop="createTime" label="生成时间"> </el-table-column>
          <el-table-column label="操作" align="center"  width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="goData(scope.row)" style="color:#1DADF9">查看详情</el-button>
            </template>
        </el-table-column>
        </el-table>
        <pagination v-show="total3 > 0" :total="total3" :page.sync="params3.pageNum" :limit.sync="params3.pageSize"
          @pagination="getSource" />
      </div>
      <div class="table-wrap-pc" style="padding-bottom: 124px" v-if="!searchStatus">
        <div class="title">商品数据</div>
        <el-table v-loading="loading4" :data="tableData4" style="width: 100%">
          <el-table-column prop="id" label="ID" width="160" show-overflow-tooltip>
            <!-- <template slot-scope="scope">
              <router-link to="/address">{{scope.row.blockNumber}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="dataHash" label="数据哈希" width="300">
            <!-- <template slot-scope="scope">
              <router-link to="/hexDetail">{{scope.row.name}}</router-link>
            </template> -->
          </el-table-column>
          <el-table-column prop="blockHash" label="区块哈希" width="300"> </el-table-column>
          <el-table-column prop="blockNumber" label="区块高度" width="130"> </el-table-column>
          <el-table-column prop="createTime" label="生成时间"> </el-table-column>
          <el-table-column label="操作" align="center"  width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="goData(scope.row)" style="color:#1DADF9">查看详情</el-button>
            </template>
        </el-table-column>
        </el-table>
        <pagination v-show="total4 > 0" :total="total4" :page.sync="params4.pageNum" :limit.sync="params4.pageSize"
          @pagination="getGoods" />
      </div>

      <div class="list-wrap-h5">
        <div class="title">{{this.title}}</div>
        <template v-if="tableData.length">
          <div class="card-item" v-for="item in tableData" :key="item.id" @click="goData(item)">
            <div class="hex">
              <div class="text">
                {{item.id}}
              </div>
              <div class="title">ID</div>
            </div>
            <div class="info">
              <div class="info-item">
                <div class="value">{{item.blockNumber}}</div>
                <div class="title">区块高度</div>
              </div>
              <div class="info-item">
                <div class="value">{{item.createTime}}</div>
                <div class="title">生成时间</div>
              </div>
            </div>
          </div>
          <div class="pagination-wrap">
            <div class="pre" @click="pre(1)">
              <div class="pre-image"></div>
              <div>上一页</div>
            </div>
            <div class="next" @click="next(1)">
              <div>下一页</div>
              <div class="next-image"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-data-h5">暂无数据</div>
        </template>
      </div>
      <div class="list-wrap-h5" v-if="!searchStatus">
        <div class="title">会员数据</div>
        <template v-if="tableData2.length">
          <div class="card-item" v-for="item in tableData2" :key="item.id" @click="goData(item)">
            <div class="hex">
              <div class="text">
                {{item.id}}
              </div>
              <div class="title">ID</div>
            </div>
            <div class="info">
              <div class="info-item">
                <div class="value">{{item.blockNumber}}</div>
                <div class="title">区块高度</div>
              </div>
              <div class="info-item">
                <div class="value">{{item.createTime}}</div>
                <div class="title">生成时间</div>
              </div>
            </div>
          </div>
          <div class="pagination-wrap">
            <div class="pre" @click="pre(2)">
              <div class="pre-image"></div>
              <div>上一页</div>
            </div>
            <div class="next" @click="next(2)">
              <div>下一页</div>
              <div class="next-image"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-data-h5">暂无数据</div>
        </template>
      </div>
      <div class="list-wrap-h5" v-if="!searchStatus">
        <div class="title">溯源数据</div>
        <template v-if="tableData3.length">
          <div class="card-item" v-for="item in tableData3" :key="item.id" @click="goData(item)">
            <div class="hex">
              <div class="text">
                {{item.id}}
              </div>
              <div class="title">ID</div>
            </div>
            <div class="info">
              <div class="info-item">
                <div class="value">{{item.blockNumber}}</div>
                <div class="title">区块高度</div>
              </div>
              <div class="info-item">
                <div class="value">{{item.createTime}}</div>
                <div class="title">生成时间</div>
              </div>
            </div>
          </div>
          <div class="pagination-wrap">
            <div class="pre" @click="pre(3)">
              <div class="pre-image"></div>
              <div>上一页</div>
            </div>
            <div class="next" @click="next(3)">
              <div>下一页</div>
              <div class="next-image"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-data-h5">暂无数据</div>
        </template>
      </div>
      <div class="list-wrap-h5" v-if="!searchStatus">
        <div class="title">商品数据</div>
        <template v-if="tableData4.length">
          <div class="card-item" v-for="item in tableData4" :key="item.id" @click="goData(item)">
            <div class="hex">
              <div class="text">
                {{item.id}}
              </div>
              <div class="title">ID</div>
            </div>
            <div class="info">
              <div class="info-item">
                <div class="value">{{item.blockNumber}}</div>
                <div class="title">区块高度</div>
              </div>
              <div class="info-item">
                <div class="value">{{item.createTime}}</div>
                <div class="title">生成时间</div>
              </div>
            </div>
          </div>
          <div class="pagination-wrap">
            <div class="pre" @click="pre(4)">
              <div class="pre-image"></div>
              <div>上一页</div>
            </div>
            <div class="next" @click="next(4)">
              <div>下一页</div>
              <div class="next-image"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-data-h5">暂无数据</div>
        </template>
      </div>
    </div>
    <H5Tab :index="2" />
  </div>
</template>

<script>
import H5Tab from '@/components/H5Tab'
import H5Navheader from '@/components/H5Navheader'
import DigitRoll from '@huoyu/vue-digitroll';
import { getBlockHeight, type_count, type_page, queryType_page } from '@/api/chain.js';
export default {
  components: {
    H5Tab,
    H5Navheader,
    DigitRoll
  },
  data() { 
    return {
      number: undefined,
      keyword: '',
      // 订单
      params: {
        page: 1,
        pageSize: 10
      },
      total: 100,
      tableData: [],
      loading: false,
      maxPage: 1, // 返回数据的页数，为解决后端页数比实际大也总是返回最后10条数据
      // 会员
      params2: {
        page: 1,
        pageSize: 10
      },
      total2: 100,
      tableData2: [],
      loading2: false,
      maxPage2: 1,
      // 溯源
      params3: {
        page: 1,
        pageSize: 10
      },
      total3: 100,
      tableData3: [],
      loading3: false,
      maxPage3: 1,
      // 商品
      params4: {
        page: 1,
        pageSize: 10
      },
      tableData4: [],
      total4: 100,
      loading4: false,
      maxPage4: 1,
      title: '订单数据',
      searchStatus: false, // 是否使用了搜索
      intervalId: undefined,
      statisticsData: [{much:0},{much:0},{much:0},{much:0}], // 统计数据
    }
  },
  created() {
    this.getBlockHeight()
    this.getStatistics()
    this.getOrder()
    this.getMember()
    this.getSource()
    this.getGoods()
    this.intervalId = setInterval(() => {
      this.getBlockHeight()
    }, 15000);
  },
  destroyed() {
    clearInterval(this.intervalId)
  },
  methods:{
    handleQuery() {
      this.searchStatus = true
      this.getQuery()
    },
    /** 获取区块高度 */
    getBlockHeight() {
      getBlockHeight().then(res => {
        this.number = this.formatZero(Number(res.data),7)
      })
    },
    /** 获取数据统计 */
    getStatistics() {
      type_count().then(res => {
        if (res.data.length > 0) {
          this.statisticsData = res.data.sort((a,b) => a.type - b.type)
        }
        // console.log(res)
      })
    },
    /** 获取订单数据 */
    getOrder() {
      this.loading = true
      type_page({...this.params,type: '1'}).then(res => {
        this.loading = false
        this.tableData = res.data.records
        this.total = res.data.total
        this.maxPage = res.data.pages
      })
    },
    /** 获取会员数据 */
    getMember() {
      this.loading2 = true
      type_page({...this.params2,type: '4'}).then(res => {
        this.loading2 = false
        this.tableData2 = res.data.records
        this.total2 = res.data.total
        this.maxPage2 = res.data.pages
      })
    },
    /** 获取溯源数据 */
    getSource() {
      this.loading3 = true
      type_page({...this.params3,type: '2'}).then(res => {
        this.loading3 = false
        this.tableData3 = res.data.records
        this.total3 = res.data.total
        this.maxPage3 = res.data.pages
      })
    },
    /** 获取商品数据 */
    getGoods() {
      this.loading4 = true
      type_page({...this.params4,type: '3'}).then(res => {
        this.loading4 = false
        this.tableData4 = res.data.records
        this.total4 = res.data.total
        this.maxPage4 = res.data.pages
      })
    },
    /** 通过搜索分页查询溯源数据 */
    getQuery() {
      this.params = {
        page: 1,
        pageSize: 10
      }
      this.loading = true
      queryType_page({...this.params,keyword:this.keyword}).then(res => {
        this.title = `已经为您找到“${this.keyword}”的相关内容`
        this.tableData = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    },
    pre(type) {
      switch (type) {
        case 1:
          if (this.params.page !== 1) {
            this.params.page --
            this.getOrder()
          }
          if (this.params.page === 1) {
            this.$toast('已经是第一页');
          }
          break;
        case 2:
          if (this.params2.page !== 1) {
            this.params2.page --
            this.getMember()
          }
          if (this.params2.page === 1) {
            this.$toast('已经是第一页');
          }
          break;
        case 3:
          if (this.params3.page !== 1) {
            this.params3.page --
            this.getSource()
          }
          if (this.params3.page === 1) {
            this.$toast('已经是第一页');
          }
          break;
        case 4:
          if (this.params4.page !== 1) {
            this.params4.page --
            this.getGoods()
          }
          if (this.params4.page === 1) {
            this.$toast('已经是第一页');
          }
          break;
      }
      
    },
    next(type) {
      console.log(type)
      switch (type) {
        case 1:
          if (this.params.page === this.maxPage) {
            this.$toast('已经是最后一页');
          } else {
            this.params.page ++
            this.getOrder()
          }
          break;
        case 2:
          if (this.params2.page === this.maxPage2) {
            this.$toast('已经是最后一页');
          } else {
            this.params2.page ++
            this.getMember()
          }
          break;
        case 3:
          if (this.params3.page === this.maxPage3) {
            this.$toast('已经是最后一页');
          } else {
            this.params3.page ++
            this.getSource()
          }
          break;
        case 4:
          if (this.params4.page === this.maxPage4) {
            this.$toast('已经是最后一页');
          } else {
            this.params4.page ++
            this.getGoods()
          }
          break;
      }
    },
    /** 跳转数据详情页 */
    goData(row) {
      this.$router.push({ path: '/chain/data',query: { id:row.id } });
    }
  }
}
</script>

<style lang='scss' scoped>
.chain {
  .block-card {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #000071,#1DADF9);
    @media screen and (min-width: 1200px) {
      padding: 12px 70px;
      border-radius: 10px;
      .image {
        background-image: url('../../assets/publicity_icon_height.png');
        width: 58px;
        height: 58px;
        background-size: cover;
        flex-shrink: 0;
      }
      .text {
        margin: 0 132px 0 28px;
        flex-shrink: 0;
        .zh {
          font-size: 18px;
          font-weight: bold;
        }
        .en {
          padding-top: 8px;
          font-size: 12px;
        }
      }
    }
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      padding: 0.3rem;
      margin: 0 0.24rem;
      border-radius: 0.15rem;
      .image {
        background-image: url('../../assets/h5_publicity_icon_height.png');
        width: 0.66rem;
        height: 0.66rem;
        background-size: cover;
        flex-shrink: 0;
      }
      .text {
        margin: 0 0 0 0.2rem;
        .zh {
          font-size: 0.3rem;
          font-weight: bold;
        }
        .en {
          font-size: 0.2rem;
          padding-top: 0.08rem;
        }
      }
    }
  }
  .data-info-wrap {
    @media screen and (min-width: 1200px) {
      padding: 33px 0 40px 0;
      .list {
        display: flex;
        align-items: center;
        .item {
          width: 290px;
          padding-right: 10px;
          .title,.en-title {
            font-size: 12px;
          }
        }
      }
    }
    @media screen and (max-width: 1200px) {
      padding: 0.3rem 0.24rem 0 0.24rem;
      .list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .item {
          width: 50%;
          margin-bottom: 0.5rem;
          .title {
            font-size: 0.24rem;
          }
          .en-title {
            font-size: 0.2rem;
          }
        }
      }
    }
  }
  .list-wrap-h5 {
    padding: 0.3rem 0;
    > .title {
      display: inline-block;
      font-size: 0.28rem;
      padding: 0.29rem 0.93rem;
      border-bottom: 1px solid #1DADF9;
    }
    .card-item {
      padding: 0.3rem;
      margin: 0.3rem 0.24rem;
      &:nth-child(even) {
        background: #202937;
      }
      &:nth-child(odd) {
        background: #151D2A;
      }
      .hex {
        .text {
          word-break: break-all;
          font-size: 0.2rem;
          color: #1DADF9;
        }
        .title {
          font-size: 0.2rem;
          color: #ababff;
          padding: 0.14rem 0 0.38rem 0;
        }
      }
      .info {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        .info-item {
          padding-right: 1.16rem;
          .value {
            font-size: 0.24rem;
            line-height: 0.24rem;
          }
          .title {
            font-size: 0.2rem;
            color: #ababff;
            padding-top: 0.1rem;
          }
        }
      }
    }
    .pagination-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0.24rem;
      font-size: 0.24rem;
      .pre,.next {
        display: flex;
        align-items: center;
        line-height: 0.24rem;
      }
      .pre-image,.next-image {
        width: 0.14rem;
        height: 0.19rem;
        background-size: contain;
      }
      .pre-image {
        background-image: url('../../assets/h5_icon_previous.png');
        margin-right: 0.12rem;
      }
      .next-image {
        background-image: url('../../assets/h5_icon_next.png');
        margin-left: 0.12rem;
      }
    }
    .no-data-h5 {
      text-align: center;
      font-size: 0.22rem;
      padding: 0.4rem 0;
    }
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.main-body {
  // padding-top: 150px;
}
.chain {
  @media screen and (min-width: 1200px) {
    .d-roll-wrapper {
      margin: 10px auto;
      .d-roll-list {
        .d-roll-item {
          border: 1px solid #BDF2FF;
          background: rgba(238,238,238,0.15);
          margin: 0 10px;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .d-roll-wrapper {
      margin: 0.2rem auto 0;
      .d-roll-list {
        .d-roll-item {
          border: 1px solid #BDF2FF;
          background: rgba(238,238,238,0.15);
          margin: 0 0.1rem;
        }
      }
    }
  }
  .main-body .el-statistic .con .number {
    @media screen and (min-width: 1200px) { 
      font-size: 40px;
      padding: 16px 0 10px 0;
      font-weight: bold;
      color: #fff;
    }
    @media screen and (max-width: 1200px) { 
      font-size: 0.66rem;
      padding: 0.3rem 0 0.2rem 0;
      font-weight: bold;
      color: #fff;
    }
  }
  .main-body .search-box {
    @media screen and (min-width: 1200px) { 
      width: 438px;
      margin-bottom: 70px;
      .el-input__inner {
        width: 438px;
        height: 34px;
        border: 1px solid #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
        border-radius: 17px;
        background: transparent;
        color: #fff;
        &:focus {
          border-color: #409EFF;
        }
      }
      .el-input__icon {
        color: #fff;
        line-height: 34px;
      }
    }
    @media screen and (max-width: 1200px) { 
      width: 6.13rem;
      margin-left: 0.24rem;
      .el-input__inner {
        width: 6.13rem;
        height: 0.48rem;
        border: 1px solid #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
        border-radius: 0.24rem;
        background: transparent;
        color: #fff;
        &:focus {
          border-color: #409EFF;
        }
      }
      .el-input__icon {
        color: #fff;
        line-height: 0.48rem;
      }
    }
  }
  
}
</style>